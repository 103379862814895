<template>
  <div>
    <!-- 大图部分 -->
    <BigBgimg :list="bigData"></BigBgimg>
    <!-- 中间内容部分 -->
    <div class="conter">
      <!-- 面包屑导航部分 -->
      <BreadNav></BreadNav>
      <!-- 列表部分 -->
      <div class="nav_content">
        <!-- 左侧标题 -->
        <ul class="left">
          <li
            :class="index == selectId ? 'select_li' : ''"
            v-for="(item, index) in leftList"
            :key="index"
            @click="show(item, index)"
          >
            {{ item.title }}
          </li>
        </ul>
        <!-- 右侧内容 -->
        <div class="right">
          <h3>{{ showItem.title }}</h3>
          <p>{{ showItem.text }}</p>
          <div class="imgBox">
            <img :src="showItem.img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadNav from "@/components/BreadNav/index"; //面包屑导航
import BigBgimg from "@/components/BigBgimg/index"; //大图
/* import bus from '@/util/bus' //传参 */

export default {
  components: { BreadNav, BigBgimg },
  data() {
    return {
      //左侧导航数据
      leftList: [
        {
          title: "人脸追踪",
          text: "我们的人脸追踪器（Face Tracker)基于深度学习框架和图形算法实现，集成了人脸检测、人脸关键点识别、人脸信息追踪、人脸姿势估计、人脸表情识别等核心技术。该技术产品提供多种平台的SDK版本，满足各种平台的应用需求。同时具备接口友好、 接入简单、稳定性高、耗能较低、包体较小等优良性能。而且我们的技术团队持续研发，周期性提供稳定的产品更新换代，让您一直享受最新的技术、更好的体验。",
          img: require("@/assets/product/intell/智能识别--人脸追踪.png"),
        },
        {
          title: "人脸比对",
          text: "我们的人脸比对技术产品包括多张人脸照片比对，视频中人脸比对等形式，可用于人脸相似度判定。既可以用于泛娱乐的场景，也可以用于安全检测系统中。我们既可以提供独立的SDK版本，也可以与其它技术产品一起集成，以满足您不同的场景需求。",
          img: require("@/assets/product/intell/智能识别--人脸比对.png"),
        },
        {
          title: "人像识别",
          text: "基于106个人脸关键点，精确识别人像，灵活应用于金融、安防、零售等应用场景，满足身份核验、人脸考勤、闸机通行等业务需求。",
          img: require("@/assets/product/intell/智能识别--人像识别.png"),
        },
        {
          title: "毛发识别",
          text: "基于自研的毛发识别和图像处理技术，可以有效的识别出发色，可应用于美妆镜、AR虚拟美发等应用场景。我们的毛发识别技术既可以提供独立的SDK版本，也可以集成在我们的人脸特效美颜SDK中。",
          img: require("@/assets/product/intell/智能识别--毛发识别.png"),
        },
        {
          title: "手势识别",
          text: "手势识别是由拓幻AI实验室推出的人机交互技术，可快速识别多种手势和手部关键点，并支持更多手势自定义。可用于短视频直播特效、智能硬件、VR/AR等领域，为人机互动带来更自然丰富的体验。",
          img: require("@/assets/product/intell/智能识别--手势识别.png"),
        },
      ],
      selectId: 0, //导航选中
      //右侧显示数据
      showItem: null,
      // 大图数据
      bigData: {
        topTitle: "智能识别",
        topText: "依托于拓幻自研的人脸追踪技术和图像处理技术，可做到智能识别",
        topBtn: "联系我们",
        topImg: require("@/assets/product/intell/智能识别.png"),
        topBg: require("@/assets/product/intell/智能识别首图bg.png"),
      },
    };
  },
  created() {
    this.toShow();
  },
  watch: {
    $route() {
      this.toShow();
    },
  },
  methods: {
    // 接收跳转显示
    toShow() {
      let index = this.$route.query.id;
      let item = this.leftList[index];
      this.show(item, index);
    },
    //点击显示
    show(item, index) {
      this.selectId = index;
      this.showItem = item;
      /* bus.$emit('getIndex',index) */
    },
  },
};
</script>
<style lang="scss" scoped>
$width: 79.1%;
//中间内容部分
.conter {
  width: $width;
  margin: auto;
  //列表部分
  .nav_content {
    display: flex;
    .left {
      margin-right: 254px;
      font-size: 14px;
      font-weight: 400;
      color: #999;
      li {
        height: 36px;
        line-height: 36px;
        cursor: pointer;
      }
      li:hover {
        color: #4c8cf5;
      }
      .select_li {
        width: 125px;
        text-align: center;
        font-size: 16px;
        color: #4c8cf5;
        background: url("~@/assets/product/icon_product_sel@2x.png") no-repeat 0
          center;
        background-size: 11px 17px;
        vertical-align: middle;
      }
    }
    .right {
      width: 69.2%;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      h3 {
        height: 36px;
        line-height: 36px;
        font-size: 16px;
        font-weight: 500;
        color: #222222;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
        margin: 20px 0;
      }
      .imgBox {
        width: 100%;
        overflow: hidden;
        margin-bottom: 80px;
        img {
          width: 100%;
          transition: all 1s;
        }
        img:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}
</style>